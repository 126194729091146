import { createMutationHook, collapseParams } from '../client';
import { postFormV2, postB2BForm } from '../services/FormsPublicV2Service';

export const useFormsPublicV2PostFormV2 = createMutationHook(
  collapseParams(
    collapseParams(postFormV2, 'requestBody', 'data'),
    'data',
    'destinationCode',
    'urlOrigin',
    'initials',
    'surnamePreposition',
    'surname',
    'emailAddress',
    'street',
    'postalCode',
    'houseNumber',
    'houseNumberSuffix',
    'city',
    'formFields',
    'attachments',
  ),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      {
        target: 'data',
        props: [
          'destinationCode',
          'urlOrigin',
          'initials',
          'surnamePreposition',
          'surname',
          'emailAddress',
          'street',
          'postalCode',
          'houseNumber',
          'houseNumberSuffix',
          'city',
          'formFields',
          'attachments',
        ],
      },
    ],
  },
);

export const useFormsPublicV2PostB2BForm = createMutationHook(
  collapseParams(collapseParams(postB2BForm, 'requestBody', 'data'), 'data', 'formId', 'publishedFormUrl', 'fields'),
  {
    injectables: ['label', 'businessUnit'],
    flattenData: false,
    flattenBodyPairs: [
      { target: 'requestBody', props: ['data'] },
      { target: 'data', props: ['formId', 'publishedFormUrl', 'fields'] },
    ],
  },
);
