import { DC_Repositories_Base_Enumerations_BusinessUnit } from '@monorepo-types/dc';
import { DC_Repositories_Base_Enumerations_Label } from '@monorepo-types/dc';
import { RequestModels_Forms_B2BFormRequest } from '@monorepo-types/dc';
import { RequestModels_Forms_V2_FormsRequestModelV2 } from '@monorepo-types/dc';

import { request } from '../client';
import type { ApiRequestConfig } from '../client/types';

type PostFormV2 = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  formId: string;
  requestBody: RequestModels_Forms_V2_FormsRequestModelV2;
  recaptchaToken?: string;
};
/**
 * PostFormV2
 * post the form
 * @returns void
 */
export function postFormV2(
  { businessUnit, label, formId, requestBody, recaptchaToken }: PostFormV2,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/public/v2/${businessUnit}/${label}/forms/${formId}`,
      body: requestBody,
      headers: { recaptchaToken },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}

type PostB2BForm = {
  businessUnit: DC_Repositories_Base_Enumerations_BusinessUnit;
  label: DC_Repositories_Base_Enumerations_Label;
  requestBody: RequestModels_Forms_B2BFormRequest;
  recaptchaToken?: string;
};
/**
 * PostB2BForm
 * Posts a form to the B2B Revolution CRM
 * @returns void
 */
export function postB2BForm(
  { businessUnit, label, requestBody, recaptchaToken }: PostB2BForm,
  requestConfig: ApiRequestConfig = {},
): Promise<void> {
  return request(
    {
      method: 'POST',
      path: `/dxpweb/public/v2/${businessUnit}/${label}/b2bforms`,
      body: requestBody,
      headers: { recaptchaToken },
      errors: { 400: 'Bad Request' },
    },
    requestConfig,
  );
}
